import React, { useEffect } from "react"
import Layout from "../components/layout"

const TermsPageRedirect = () => {
  useEffect(() => {
    window.location = "/serviceterms/"
  }, [])

  return <Layout></Layout>
}

export default TermsPageRedirect
